angular.module('MyHippoProducer.Services').factory('PolicyService', function (APIService, $http, $rootScope, toaster, $stateParams, $state, UserService) {
    const { API_HOST: apiHost } = window.appConfig;
    const policyDataDefaults = {
        policyInfo: {},
        hasTransaction: false,
        workflow: {},
        terms: [],
        policyInfoCopy: {},
        currentPremium: 0,
        smartHome: { kitsInfo: {}},
    };
    const generateNewPolicyData = () => (_.cloneDeep(policyDataDefaults));
    let policyData = generateNewPolicyData();

    const convertErrors = (errors, errorType) => {
        if (Array.isArray(errors) && errors.length) {
            return errors.reduce((errors, error) => {
                const { fields, agent_message: errorMessage } = error;
                fields.forEach(key => {
                    if (!errors[key]) { errors[key] = {}; }

                    let modifiedErrorMsg = errorMessage;
                    if (errorType === 'underwriting') {
                        modifiedErrorMsg = `Underwriting: ${modifiedErrorMsg}`;
                    }
                    // Translate errors
                    if (errorMessage) {
                        if (errorMessage.indexOf('value >') === 0) {
                            modifiedErrorMsg = errorMessage.replace('value >', 'Value should be less than or equal to');
                        } else if (errorMessage.indexOf('value <') === 0) {
                            modifiedErrorMsg = errorMessage.replace('value <', 'Value should be greater than or equal to');
                        }
                    }
                    errors[key][errorType] = modifiedErrorMsg;
                });

                return errors;
            }, {});
        }
        return {};
    };

    function fetch (policyId, isTransaction = false, isNewPolicy = false) {
        return $http.get(`${apiHost}/v1/producer/policy/${policyId}${isTransaction ? '/transaction' : ''}?smartHome=true`).then((response) => {
            if(isNewPolicy) {
                policyData = generateNewPolicyData();
            }
            _.assign(policyData, {
                hasTransaction: response.data.hasTransaction,
                workflow: response.data.workflow,
                terms: response.data.allTerms,
                policyInfo: response.data.policyInfo,
                policyInfoCopy: _.cloneDeep(response.data.policyInfo),
                transactionLastModified: response.data.transactionLastModified,
                errors: convertErrors(response.data.underwriting || [], 'underwriting'),
                currentPremium: response.data.currentPremium,
                smartHome: {
                    kitsInfo: response.data.smartHomeKitsInfo,
                },
            });
        });
    }

    function fetchTransactionBreakdown (policyId) {
        return $http.get(`${apiHost}/v1/producer/policy/${policyId}/transaction_breakdown`).then((response) => {
            return response.data;
        });
    }

    let policyLock = { ownerId: null, policyId: null };
    function lockPolicy (policyId, forceLock) {
        return $http({
            url: `${apiHost}/v1/producer/policy/${policyId}/lock`,
            method: 'POST',
            data: { force_lock: forceLock },
            cache: false,
        }).then(() => {
            const user = UserService.getCurrentUser();
            policyLock = { ownerId: user.id, policyId: policyId };
        }).catch(() => {
            policyLock = { ownerId: null, policyId: null };
        });
    }

    function unlockPolicy (policyId) {
        return $http({
            url: `${apiHost}/v1/producer/policy/${policyId}/unlock`,
            method: 'POST',
            cache: false,
        }).then(() => {
            policyLock = { ownerId: null, policyId: null };
        });
    }

    function getUpdates () {
        function isEmpty (obj) {
            return obj === undefined
                || (Array.isArray(obj) && obj.length === 0)
                || (_.isPlainObject(obj) && Object.keys(obj).length === 0);
        }
        function changes(modified, base) {
            if (_.isArray(modified) && modified.every(item => item.id !== undefined)) {
                // remove hash key
                modified.forEach(item => delete item.$$hashKey);
                if (!base) {
                    base = [];
                }
                base.forEach(item => delete item.$$hashKey);
                const allIds = _.unionBy(modified, base, 'id').map(i => i.id);
                const arrayUpdate = modified.reduce((update, item) => {
                    const itemId = item.id;
                    const baseItem = base.find(i => i.id === itemId);
                    if (!baseItem) { // Add
                        const newItem = _.cloneDeep(item);
                        delete newItem.id;
                        _.set(update, itemId, newItem);
                    } else { // Update
                        const differences = changes(item, baseItem);
                        if (!isEmpty(differences)) {
                            _.set(update, itemId, changes(item, baseItem));
                        }
                    }
                    _.remove(allIds, (id) => id === itemId);
                    return update;
                }, {});
                // Delete
                allIds.forEach(id => {
                    arrayUpdate[id] = null;
                });
                return isEmpty(arrayUpdate) ? undefined: arrayUpdate;
            } else if (_.isPlainObject(modified)) {
                if (!base) {
                    base = {};
                }
                const keysToIgnore = ['delta', 'quote', 'term_quote', 'previous_ach_info'];
                const ch = _.transform(modified, function(update, value, key) {
                    if (!_.includes(keysToIgnore, key)) {
                        const baseValue = base[key];
                        if (_.isObject(value)) {
                            const difference = changes(value, baseValue);
                            if (!isEmpty(difference) && difference !== undefined) {
                                update[key] = changes(value, baseValue);
                            }
                        } else if (value !== baseValue) {
                            update[key] = value;
                        }
                    }
                });
                return ch;
            }
            return undefined;
        }

        return changes(policyData.policyInfo, policyData.policyInfoCopy);
    }

    function hasUpdates () {
        return !_.isEmpty(getUpdates());
    }

    function getTermQuote () {
        return _.get(policyData.policyInfo, 'term_quote');
    }

    function getPolicyOverview () {
        const { policyInfo } = policyData;
        // Customer Name
        const personalInformation = _.get(policyInfo, 'personal_information', {});
        const customer = `${personalInformation.first_name} ${personalInformation.last_name}`;
        // Address
        const address = _.get(policyInfo, 'property_data.address', {});
        const street = address.street2 ? `${address.street} ${address.street2}` : address.street;
        const fullAddress = `${street} ${address.city}, ${(address.state || '').toUpperCase()} ${address.zip}`;
        // Premium
        const premium = _.get(policyInfo, 'term_quote.total', 0);
        // Policy Number
        const policyNumber = _.get(policyInfo, 'policy_number');
        return { customer, policyNumber, address: fullAddress, premium };
    }

    /* Workflow Getters */
    function getWorkflow() {
        return policyData.workflow;
    }

    function getAllStates () {
        return {
            'al': 'Alabama',
            'ak': 'Alaska',
            'az': 'Arizona',
            'ar': 'Arkansas',
            'ca': 'California',
            'co': 'Colorado',
            'ct': 'Connecticut',
            'de': 'Delaware',
            'dc': 'District Of Columbia',
            'fl': 'Florida',
            'ga': 'Georgia',
            'hi': 'Hawaii',
            'id': 'Idaho',
            'il': 'Illinois',
            'in': 'Indiana',
            'ia': 'Iowa',
            'ks': 'Kansas',
            'ky': 'Kentucky',
            'la': 'Louisiana',
            'me': 'Maine',
            'md': 'Maryland',
            'ma': 'Massachusetts',
            'mi': 'Michigan',
            'mn': 'Minnesota',
            'ms': 'Mississippi',
            'mo': 'Missouri',
            'mt': 'Montana',
            'ne': 'Nebraska',
            'nv': 'Nevada',
            'nh': 'New Hampshire',
            'nj': 'New Jersey',
            'nm': 'New Mexico',
            'ny': 'New York',
            'nc': 'North Carolina',
            'nd': 'North Dakota',
            'oh': 'Ohio',
            'ok': 'Oklahoma',
            'or': 'Oregon',
            'pa': 'Pennsylvania',
            'ri': 'Rhode Island',
            'sc': 'South Carolina',
            'sd': 'South Dakota',
            'tn': 'Tennessee',
            'tx': 'Texas',
            'ut': 'Utah',
            'vt': 'Vermont',
            'va': 'Virginia',
            'wa': 'Washington',
            'wv': 'West Virginia',
            'wi': 'Wisconsin',
            'wy': 'Wyoming'
        };
    }

    function getSmartHome() {
        return policyData.smartHome;
    }

    const policyService = {
        fetch,
        fetchTransactionBreakdown,
        getPolicyOverview,
        getSmartHome,
        getTermQuote,
        getWorkflow,
        getUpdates,
        hasUpdates,
        getAllStates,
        lockPolicy,
        unlockPolicy,
    };

    return Object.defineProperties(policyService, {
        policyInfo: {
            get: function () { return policyData.policyInfo; },
            set: function (value) { policyData.policyInfo = value; },
        },
        id: {
            get: function () { return policyData.policyInfo.id; },
            set: function (newId, mode = 'view') {
                $state.go('portal.policyDetailsV2', { id: newId, mode });
            },
        },
        hasActiveTransaction: {
            get: function () { return policyData.hasTransaction; },
        },
        errors: {
            get: function () { return policyData.errors || {}; },
            set: function (errors) {
                if (errors && errors.length === 0) {
                    policyData.errors = {};
                } else {
                    const serverErrors = convertErrors(errors, 'server');
                    policyData.errors = _.merge(policyData.errors || {}, serverErrors);
                }
            },
        },
        overview: {
            get: function () {
                const { policyInfo, currentPremium } = policyData;
                // Customer Name
                const personalInformation = _.get(policyInfo, 'personal_information', {});
                const customer = `${personalInformation.first_name} ${personalInformation.last_name}`;
                // Address
                const address = _.get(policyInfo, 'property_data.address', {});
                const street = address.street2 ? `${address.street} ${address.street2}` : address.street;
                const fullAddress = `${street} ${address.city}, ${(address.state || '').toUpperCase()} ${address.zip}`;
                // Premium
                const premium = _.get(policyInfo, 'term_quote.total', 0);
                // Policy Number
                const policyNumber = _.get(policyInfo, 'policy_number');
                return { customer, policyNumber, address: fullAddress, premium, currentPremium };
            },
        },
        transactionLastModified: {
            get: function () { return policyData.transactionLastModified; },
        },
        isLatestTerm: {
            get: function () {
                const termIdx = policyData.terms.findIndex(t => t.policy_id === policyData.policyInfo.id);
                return termIdx === policyData.terms.length - 1;
            },
        },
        terms: {
            get: function () { return policyData.terms; },
        },
        isEditMode: {
            get: function () { return $stateParams.mode === 'edit'; },
        },
        allowEndorsement: {
            get: function () {
                const validPolicyStates = ['pending_active', 'active'];
                return policyService.isLatestTerm && _.includes(validPolicyStates, policyService.policyInfo.status);
            },
        },
        isPolicyLockOwner: {
            get: function () { return policyLock.ownerId === UserService.getCurrentUser().id; }
        },
        cartInfo: {
            get: function() {
                const { policyInfo } = policyData;
                const discount = _.get(policyInfo.smart_home, 'discount', 0);
                const isSmartHomeDiscountActive = policyInfo.property_data.smart_home_kit_activated;
                const smartHomeDiscountAdjustment = isSmartHomeDiscountActive ? -discount : 0;

                // Breakdown
                const policyFees = getTermQuote().total_fees;
                const optionals = policyInfo.quote.premium.optionals;
                const base = policyInfo.quote.premium.base - smartHomeDiscountAdjustment;
                const total = [policyFees, optionals, base, smartHomeDiscountAdjustment]
                    .filter(value => !!value)
                    .reduce((sum, value) => sum + value, 0);
                return { smartHomeDiscountAdjustment, policyFees, optionals, base, total, proratedAmount: getTermQuote().total };
            }
        }
    });
});
